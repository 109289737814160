export const environment = {
  production: false,
  ApiUrl: 'https://ppd.authorityhub.io',
  env: 'ppd',
  tenant: 'common',
  clientId: '62bf1726-d500-4055-838f-878ee15eda5e',
  redirectUri: 'https://ppd.authorityhub.io/admin',
  navigateToLoginRequestUrl: false,
  cacheLocation: '<localStorage / sessionStorage>',
  endPoints: {
    '/':
      'https://charlestaylorplc.onmicrosoft.com/c92aa1c4-5aba-4fc0-8f1e-60e830310df9',
    'https://ppd.authorityhub.io/':
      'https://charlestaylorplc.onmicrosoft.com/c92aa1c4-5aba-4fc0-8f1e-60e830310df9'
  },
  logo: 'assets/images/QBELogo.svg',
  useNewDALContent: true,
  productVersion: '2.1.0.10',
  showBanner:true
};
